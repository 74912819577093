import React, { Component } from 'react'
import {Form, Button, Row, Col, Card} from 'react-bootstrap'
import axios from "axios";
import Loader from "../shared/Loader";
import helpers from "../shared/layouts/helpers";
import Swal from "sweetalert2";
import AuthService from '../services/auth-service';

class Login extends Component {
    constructor(props) {
        super(props);
        document.body.style.background = "linear-gradient(to right, #243449, #52768f)";


        if (!AuthService.getCurrentUser() && (window.location.pathname).length > 1) {
           window.location = "/";
        }

        let lctn = window.location.href;
        let url = new URL(lctn);
        let ref = url.searchParams.get("ref");
        document.title = "Partner Portal | " + helpers.getSystem()

        this.state = {
            ref: ref,
            username: "",
            password: "",
            rememberMe: "",
            loading: false,
            error: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true, error: false});

        AuthService.login(this.state.username, this.state.password, this.state.country).then(
            () => {
                window.location.reload();
            },
            error => {
                Swal.fire(
                    'Invalid credentials!',
                    'Please check your username and password.',
                    'error',
                );

                this.setState({
                    loading: false,
                });
            }
        );
    };

    componentDidMount() {
        if (this.state.ref) {
            this.setState({country: this.state.ref})
        } else {
            axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                if (helpers.isValidCountry(data.country_code)) {
                    this.setState({country: data.country_code})
                }
            }).catch(error => {
            });
        }
    }

    render() {
        return (
            <div className="authentication-wrapper authentication-1 px-4">
                {this.state.loading && <Loader/>}

                <div className="authentication-inner py-5">


                    <div className="d-flex justify-content-center align-items-center">
                        <div className="ui-w-250">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '20%' }}>
                                <img className={"mb-2"} style={{width: '250px'}} src={helpers.getLogo()}/>
                                <br/>
                                <small as={"h6"} className={"text-white mt-4"} style={{ letterSpacing: '0.15em' }}><strong>PARTNER</strong></small>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <div className="col-md-4 my-5">
                            <Card>
                                <Card.Body>
                                    <form onSubmit={this.handleSubmit}>
                                        <Form.Group>
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control name="username" value={this.state.username} onChange={this.handleInputChange}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className="d-flex justify-content-between align-items-end">
                                                <div>Password</div>
                                            </Form.Label>
                                            <Form.Control name="password" type="password" value={this.state.password} onChange={this.handleInputChange}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className="d-flex justify-content-between align-items-end">
                                                <div>Connect to</div>
                                            </Form.Label>
                                            <Form.Control required as={"select"} name={"country"} value={this.state.country} onChange={this.handleInputChange} defaultValue={""}>
                                                <option disabled value={""}>Please select an option</option>
                                                {JSON.parse(process.env.REACT_APP_API_LIST)[0][helpers.getHost()].map(item => (
                                                    <option key={item.code} value={item.code}>{item.country}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <div className="d-flex justify-content-between align-items-center m-0">
                                            <Form.Check type="checkbox" name="rememberMe" custom checked={this.state.rememberMe} onChange={this.handleInputChange} label="Remember me" className="m-0" id="login-remember-me"/>
                                            <Button type={"submit"} variant="primary">Sign In</Button>
                                        </div>

                                        {this.state.country && <div className={"text-center"}>
                                            <hr/>
                                            <a href={helpers.getAPI(this.state.country) + "/sso/login?sub=partner"} className={"btn btn-windows btn-block mt-4"}><i className={"fab fa-windows"}/> Sign in with Microsoft Authenticator</a>
                                        </div>}
                                    </form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default Login
